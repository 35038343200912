
/* Section Video Style.
  ========================================================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.g-2 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  [class*=col-] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.section {
  color: $blue-dark;
  background-image: url(../assets/img/bg-footer.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  min-height: 100vh;

  &__main {
    display: flex;
    /*align-items: center;*/
    /*background-image: url(../assets/img/Gradient-Headers.png);*/
    background-position: right 91px;
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding: 8rem 0 4rem 0;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .fs-14 {
    font-size: 14px;
  }
  .fs-15 {
    font-size: 15px;
  }

  .fs-18 {
    font-size: 18px;
  }

  .text-label {
    text-transform: uppercase;
    opacity: 0.5;
  }

  .star-rating {
    display: inline-block;
    background-image: url(../assets/img/star-rating.png);
    background-repeat: no-repeat;
    background-position: left top;
    height: 50px;
    width: 250px;
    cursor: pointer;
    position: relative;

    &.star-rating-1,
    &.star-rating-2,
    &.star-rating-3,
    &.star-rating-4,
    &.star-rating-5 {
      background-image: url(../assets/img/star-rating.png);
    }
    &.star-rating-1 {
      background-position: 0 -50px;
    }
    &.star-rating-2 {
      background-position: 0 -100px;
    }
    &.star-rating-3 {
      background-position: 0 -150px;
    }
    &.star-rating-4 {
      background-position: 0 -200px;
    }
    &.star-rating-5 {
      background-position: 0 -250px;
    }

    &--1,
    &--2,
    &--3,
    &--4,
    &--5 {
      top: 0;
      height: 50px;
      width: 50px;
      position: absolute;
      z-index: 1;
    }

    &--1 {
      left: 0;
    }
    &--2 {
      left: 50px;
    }
    &--3 {
      left: 100px;
    }
    &--4 {
      left: 150px;
    }
    &--5 {
      left: 200px;
    }
  }

  &__header {
    background: $blue-dark;
    display: block;
    margin-bottom: 2rem;
    padding: 1.25rem 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &-logo {
      max-width: 200px;
    }
  }

  &__headline {
    font-size: 14px;
    font-weight: 600;
    color: #73acdd;
    display: block;

  }
  &__smalltitle {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
  }
  &__title {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &__bigtitle {
    font-size: 3.75rem;
    font-weight: 400;
  }

  &__subtitle {
    color: #73acdd;
    font-size: 1.5rem;
    font-weight: 400;
  }
  &__info {
    margin: 0 2rem;
  }

  &__block {
    background: rgba(17, 17, 17, 0.9);
    border-radius: 0.625rem;
    color: rgba(255,255,255,0.7);
    display: block;
    font-size: 1.05rem;
    padding: 2rem;
  }

  &__video {
    background: $blue-dark;
    border-radius: 0.625rem;
    color: $white;
    display: block;
    font-size: 0.875rem;
    padding: 1rem;

    &-panel {
      background: $blue-lighter;
      border-radius: 0.625rem;
      display: block;
      font-size: 0.875rem;
      padding: 1rem;

      video {
        border-radius: 0.5rem;
        display: block;
        margin: 0;
      }
    }

    
  }

  .video-info {
    display: block;
    margin: 0;
    padding: 0;

    &:after {
      content: '';
      display: block;
      clear: both;
    }


    &__item {
      display: block;
      padding: 0.5rem 0;
      border-top: 0.0625rem solid rgba(0,0,0,0.1);
      position: relative;

      strong {
        opacity: 0.75;
        font-size: 0.875rem;
        font-weight: 400;
      }

    }

    &__link {
      display: block;
      font-size: 1rem;
      color: $blue-dark;
      cursor: pointer;
      opacity: 0.75;
      transition: all .2s linear;

      &:hover {
        opacity: 1;
      }

      &.is-active {
        opacity: 1;
        font-weight: 700;
      }
    }

    &__icon {
      height: 0.875rem;
      width: 0.875rem;
      fill: $white;
      opacity: 0.75;
      vertical-align: text-top;
      margin-right: 0.25rem;
    }
  }

  .list {
    list-style: disc;
    margin: 0;
    padding: 0 0 0 2rem;
  }

}

/* Media Screen Style.
    ========================================================================= */

/*media screen for width 991px and down*/
@include media-breakpoint-down(md) {
  .section {
    font-size: 1rem;

    &__main {
      padding: 7rem 0 4rem 0;
    }

    &__smalltitle {
      font-size: 1.125rem;
    }
    &__title {
      font-size: 2rem;;
    }
  
    &__bigtitle {
      font-size: 3rem;
    }
  
    &__subtitle {
      font-size: 1.25rem;
    }
  }
  .video-info {

    &__item {
      float: left;
      width: 50%;

      &:first-child {
        border-top:none;
      }

    }
  }
  
}

/*media screen for width 767px and down*/
@include media-breakpoint-down(sm) {
  .section {
    line-height: 1.3125;

    &__main {
      background-position: right 70px;
      padding: 5.5rem 0 4rem 0;
    }

    &__header {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
  
      &-logo {
        max-width: 150px;
      }
    }

    &__headline {
      font-size: 1.375rem;
      padding: 0 3rem;
    }

    &__smalltitle {
      font-size: 1rem;
    }
    &__title {
      font-size: 1.5rem;
    }
  
    &__bigtitle {
      font-size: 2.5rem;
    }
  
    &__subtitle {
      font-size: 1rem;
    }

    &__info {
      margin: 0 1.5rem;
    }

    &__block {
      border-radius: 0.375rem;
      font-size: 1.125rem;
      padding: 1.5rem;
    }
  
    &__footer {
      padding: 1.5rem;
    }
  
  }
}

/*media screen for width 575px and down*/
@include media-breakpoint-down(xs) {
  .section {
    font-size: 0.875rem;
    line-height: 1.25;

    &--video {
      background-position: 75% top;
    }
  
    &__headline {
      font-size: 1.25rem;
      padding: 0 2rem;
    }

    &__info {
      margin: 0 1rem;
    }

    &__block {
      border-radius: 0.3125rem;
      font-size: 1rem;
      padding: 1rem;
    }
  
    &__footer {
      padding: 1rem;
    }
  
  }
  .video-info {
    &__item {
      float: none;
      width: 100%;
    }
  }
}

