/* ============================================================================
   SVG -> Main -> Aside -> Search Control -> Media Screen
   ========================================================================= */

/**
  * 1. SVG Style.
  * 2. Main Style.
  * 3. Aside Style.
  * 4. Search Control Style.
  * 5. Media Screen Style.
 */

/* 1. SVG Style.
   ========================================================================= */

.svg-0-75 {
  height: 0.75rem;
  width: 0.75rem;
}
.svg-1 {
  height: 1rem;
  width: 1rem;
}
.svg-1-5 {
  height: 1.5rem;
  width: 1.5rem;
}
.svg-1-125 {
  height: 1.125rem;
  width: 1.125rem;
}
.svg-2-5 {
  height: 2.5rem;
  width: 2.5rem;
}
.svg-primary {
  fill: $primary;
  stroke: $primary;
}
.svg-secondary {
  fill: $secondary;
  stroke: $secondary;
}

/* 2. Main Style.
   ========================================================================= */

.main {
  padding-left: 75px;
  position: relative;

  &__logo {
    display: block;
    margin: 0 auto 2rem auto;
  }

  &__nav {
    &::-webkit-scrollbar {
      height: 0.25rem;
      width: 0.25rem;
    }
    background: $white;
    width: 75px;
    padding: 1rem 0.5rem;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    overflow-y: auto;
    overflow-x: visible;
    text-align: center;

    &-block {
      min-height: calc(100vh - 5.25rem);
    }

    &-link {
      display: block;
      padding: 1rem;
      height: 3.25rem;
      width: 3.25rem;
      margin: 0 auto;
      border-radius: 50%;
      transition: $transition-base;
      position: relative;

      &.is-active,
      &:hover {
        background: $blue-lighter;
      }
    }

    &:hover {
      .main__nav-link.is-active {
        background: none;

        &:hover {
          background: $blue-lighter;
        }
      }
    }
  }

  &__content {
    border-left: 0.0625rem solid #eaeef7;
    display: block;
    min-height: 100vh;
    padding: 1rem 1.5rem;
  }

  &__header {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 1rem 0;
  }

  &__subheader {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.05);
    background: rgba(213, 234, 253, 0.29);
    padding: 1rem 1.5rem;
    margin: 0.25rem -1.5rem 1.5rem -1.5rem;
    font-weight: 700;
    font-size: 0.875rem;
  }
}

/* 3. Aside Style.
   ========================================================================= */

.aside {
  &::-webkit-scrollbar {
    height: 0.25rem;
    width: 0.25rem;
  }

  background: #f0f3f4;
  border-right: 0.0625rem solid #eaeef7;
  padding: 1rem 1.5rem;
  position: fixed;
  top: 0;
  left: 75px;
  bottom: 0;
  width: 375px;
  overflow-y: auto;
  z-index: 3;

  &__overlay {
    background: rgba(0, 0, 0, 0.6);
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: $transition-base;
    opacity: 0;
  }

  &__wrap {
    position: relative;
    padding-left: 375px;
    min-height: 100vh;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    color: $primary;
    margin: 0 0 1rem 0;
  }

  &__tabs {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.05);
    margin: 1rem -1.5rem 0 -1.5rem;
    padding: 0;

    &-item {
      border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.05);
      display: block;
    }

    &-info {
      font-size: 0.75rem;
      color: $gray-600;
      font-weight: 400;
      font-style: italic;
      display: block;
    }

    &-link {
      color: $gray-900;
      font-size: 1rem;
      font-weight: 700;
      display: block;
      padding: 1rem 1.5rem;
      position: relative;
      transition: $transition-base;

      &.is-active,
      &:hover {
        background: $gray-100;
        color: $gray-900;

        .aside__tabs-info {
          color: $gray-600;
        }
      }
    }

    &-icon {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      height: 1rem;
      width: 1rem;
      opacity: 0.75;
      transition: $transition-base;

      &:hover {
        opacity: 1;
      }

      img {
        display: block;
        height: 1rem;
        width: 1rem;
      }
    }

    &:hover {
      .aside__tabs-link.is-active {
        background: none;

        &:hover {
          background: $gray-100;
        }
      }
    }
  }
}

/* 4. Search Control Style.
   ========================================================================= */

.search-control {
  position: relative;

  .form-control {
    border: 2px solid #d8def0;
    border-radius: 0.25rem;
    background: $white;
    height: 2.5rem;
    padding: 0.375rem 0 0.375rem 2.5rem;

    &:focus,
    &:hover {
      border: 0.125rem solid $primary;
      transition: $transition-base;
    }
  }

  &:after {
    position: absolute;
    top: 0.75rem;
    left: 1rem;
    content: "";
    padding: 0.375rem 0 0.375rem 0.75rem;
    background: url(../assets/img/icon-search.svg);
    background-size: 100%;
    width: 1rem;
    height: 1rem;
    z-index: 1;
  }
}

/* 5. Media Screen Style.
   ========================================================================= */

/*media screen for width 992px and up*/
@include media-breakpoint-up(lg) {
}

/*media screen for width 1199px and down*/
@include media-breakpoint-down(lg) {
  .aside {
    left: -300px;
    transition: $transition-base;

    &__wrap {
      padding-left: 0;
      min-height: 100vh;
    }
  }

  .is-menu {
    overflow: hidden;

    &__show {
      display: none;
    }

    &__hide {
      display: block;
    }

    .is-menu__show {
      display: block;
    }

    .is-menu__hide {
      display: none;
    }

    .aside {
      left: 75px;

      &__overlay {
        display: block;
        opacity: 1;
      }
    }
  }
}

/*media screen for width 575px and down*/
@include media-breakpoint-down(sm) {
  .aside {
    left: calc(-100% + 75px);
    width: calc(100% - 75px);
  }
}
